.login-inner-container {
  max-width: 408px;
  width: 100%;
}

.login-box {
  border-radius: 5px;
border: 1px solid var(--DSM_Gray-03, #D0D9E3);
background: #FFF;
box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.12), 0px 4px 6px -2px rgba(16, 24, 40, 0.07);
  padding: 52px 24px;
  gap: 3.6rem;
  width: 100%;
  min-width: 42rem;
  display: flex;
  flex-direction: column;
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}

.app-version-container {
  position: absolute;
  bottom: 0px;
  right: 10px;
  font-size: 9px;
}
