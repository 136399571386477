.App {
  text-align: center;
}

.test {
  display: block;
}

/* unvisited link */
a:link {
  color: #fff;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: #fff;
}

/* mouse over link */
a:hover {
  color: #fff;
}

/* selected link */
a:active {
  color: #fff;
}

.link {
  display: flex;
  align-items: center;
}

img {
  width: 100%;
}

.boldText {
  font-weight: 800;
}

.pointerCursor {
  cursor: pointer;
}

.test-class-will-be-deleted {
  display: flex;
}

.icon20 {
  width: 2rem;
  height: 2rem;
}

.icon12 {
  width: 1.2rem;
  height: 1.2rem;
}

.markdown-image {
  max-width: 70rem !important;
  max-height: 40.4rem !important;
}

.dot {
  border-radius: 200px;
  width: .8rem;
  height: .8rem;
}

.container__borderAndShadow {
  border-radius: 5px;
  border: 1px solid var(--DSM_Gray-02, #E5EBF2);
  background: #FFF;

/* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}